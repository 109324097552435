export default {
  branch(state) {
    return state.branch
  },
  cartQty(state) {
    return state.cartQty
  },
  defaultAddress(state) {
    return state.defaultAddress
  },
  countryList(state){
    return state.countryList
  },
  stateList(state){
    return state.stateList
  },
  shippingMethods(state){
    return state.shippingMethods
  }
};