export default { 
    setCartQty(state, data) {
      if (!!data) {
        state.cartQty = data
        //store user in local storage
        localStorage.setItem('cartQty', data)
      }
      else {
        state.setCartQty = 0
        localStorage.removeItem('cartQty')
      }
    },
    setDefaultAddress(state, data) {
      if (!!data) {
        state.defaultAddress = data
      }
      else {
        state.defaultAddress = {}
      }
    },
    setCountryList(state, data){
      if (!!data) {
        state.countryList = data
      }
      else {
        state.countryList = []
      }
    },
    setStateList(state, data){
      if (!!data) {
        state.stateList = data
      }
      else {
        state.stateList = []
      }
    },
    setShippingMethods(state, data){
      if (!!data) {
        state.shippingMethods = data
      }
      else {
        state.shippingMethods = {}
      }
    }
  };