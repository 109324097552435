import axios from 'axios'
import store from '../store/index'
import useRefresh from './useRefresh.js'
import rounter from 'vue-router'
import { BackendDomain } from "./settings.js";
//import { BackendDomain } from '../../vue.config'
//import { publicPath } from '../../vue.config'


const getAPI = axios.create({
    baseURL: BackendDomain, 
})

const getAuthAPI = axios.create({
    baseURL: BackendDomain, 
})

const getAuthAPIPoSubmit = axios.create({
    baseURL: BackendDomain, 
})
getAuthAPIPoSubmit.interceptors.request.use((config) => {
    const accessToken = store.getters.accessToken;
    config.headers['Authorization'] = 'Bearer ' + accessToken;
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8'
    return config;
  },
  (error) => {
    Promise.reject(error)
});


getAuthAPIPoSubmit.interceptors.response.use(
(response) => {
    // Return a successful response back to the calling service
    return response;
},
async (error, config) => {  
    //const router = useRouter()
    const originalRequest = error.config
    const refreshEndpoint = BackendDomain + '/api/token/refresh/'

    //if resfresh failed, redirect to login page
    if (error.response.status === 401 && originalRequest.url === refreshEndpoint) {
        window.location.href = "/login"
        return Promise.reject(error)
    }
        
    if (error.response.status === 401 && !originalRequest._retry) {
        
        originalRequest._retry = true
        
        const { refresh } = useRefresh()
        status = await refresh()
        
        //refresh successfully
        if (status == "success") {
            //Change Authorization header
            originalRequest.headers['Authorization'] = 'Bearer ' + store.getters.accessToken
            
            //return originalRequest object with Axios.
            return axios(originalRequest)
        }
        //refresh failed, redirect to login page
        else {
            window.location.href = "/login"
            return Promise.reject(error)
        }
        
    } else {
        // Return any error which is not due to authentication back to the calling service
        return Promise.reject(error);
    }
    
});



getAuthAPI.interceptors.request.use((config) => {
    const accessToken = store.getters.accessToken;
    config.headers['Authorization'] = 'Bearer ' + accessToken;
    return config;
  },
  (error) => {
    Promise.reject(error)
});


getAuthAPI.interceptors.response.use(
(response) => {
    // Return a successful response back to the calling service
    return response;
},
async (error, config) => {  
    //const router = useRouter()
    const originalRequest = error.config
    const refreshEndpoint = BackendDomain + '/api/token/refresh/'

    //if resfresh failed, redirect to login page
    if (error.response.status === 401 && originalRequest.url === refreshEndpoint) {
        window.location.href = "/login"
        return Promise.reject(error)
    }
        
    if (error.response.status === 401 && !originalRequest._retry) {
        
        originalRequest._retry = true
        
        const { refresh } = useRefresh()
        status = await refresh()
        
        //refresh successfully
        if (status == "success") {
            //Change Authorization header
            originalRequest.headers['Authorization'] = 'Bearer ' + store.getters.accessToken
            
            //return originalRequest object with Axios.
            return axios(originalRequest)
        }
        //refresh failed, redirect to login page
        else {
            window.location.href = "/login"
            return Promise.reject(error)
        }
        
    } else {
        // Return any error which is not due to authentication back to the calling service
        return Promise.reject(error);
    }
    
});

export { getAPI, getAuthAPI,getAuthAPIPoSubmit }