import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

export default {
  state() {
    return {
      branch: {'sj': {'code':'', 'name':'San Jose', 'stock': '', 'qty': ''}, 
              'la':  {'code':'L', 'name':'Los Angeles', 'stock': '', 'qty': ''},
              'mi': {'code':'M', 'name':'Miami', 'stock': '', 'qty': ''},
              'ge': {'code':'A', 'name':'Georgia', 'stock': '', 'qty': ''},
              'nj': {'code':'N', 'name':'New Jersey', 'stock': '', 'qty': ''},
              'il': {'code':'C', 'name':'Chicago', 'stock': '', 'qty': ''}
              },
      cartQty:localStorage.getItem('cartQty') || 0,
      defaultAddress: {},
      countryList: [],
      stateList: [],
      shippingMethods: {}
    };
  },
  mutations,
  actions,
  getters
};