import { jwtDecrypt, addDays } from "../../../utils/common";

export default {
  setToken(state, data){
    state.accessToken = data.accessToken
    
    //set access token exp
    if (!!data.accessToken) {
      var jwtDecodedValue = jwtDecrypt(data.accessToken)
      state.tokenExp = jwtDecodedValue.exp
      localStorage.setItem('tokenExp', state.tokenExp)
    }
    else {
      state.tokenExp = ''
      localStorage.removeItem('tokenExp')

    }
    //set refresh token exp
    if (state.tokenExp) {
      state.refreshExp = addDays(state.tokenExp, 1)
      localStorage.setItem('refreshExp', state.refreshExp)
    }
    else {
      state.refreshExp = ''
      localStorage.removeItem('refreshExp')
    }
  },
  setUser(state, data) {
    if (!!data) {
      state.user = data
      //store user in local storage
      localStorage.setItem('user', JSON.stringify(data))
    }
    else {
      state.user = {}
      localStorage.removeItem('user')
    }
  },
  setAuthStatus(state, data){
    state.authStatus = {status: data.status, message: data.message}
  },
  setEblast(state, data) {
    state.user['eblast'] = data
    localStorage.setItem('user', JSON.stringify(state.user))
  }
};