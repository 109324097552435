<template>

  <div v-if="$route.name == 'Quote'">
    <div class="main_content">
      <router-view :key="$route.fullPath" />
    </div>
  </div>

  <div v-else-if="$route.name == 'EblastFlyer'">
    <div class="main_content">
      <router-view :key="$route.fullPath" />
    </div>
  </div>

  <div v-else-if="$route.name == 'QuoteRequestPrint'">
    <div class="main_content">
      <router-view :key="$route.fullPath" />
    </div>
  </div>

  <div v-else-if="$route.name == 'TechFairRegister'">
    <div class="main_content">
      <router-view :key="$route.fullPath" />
    </div>
  </div>

  <div v-else>
    <!-- <div v-if="$route.name !== 'Quote'" class=" text-center pt-2 pb-2 " style="background-color:#FFEB3B;">
      <span class="fw-bold">New Updates</span> - login account has changed to <span class="fw-bold">Email Address</span> instead of customer ID. Please contact your account manager for further assistance. 
    </div> -->
    <div class="container-fluid">
      <Navbar 
        :username="username"
        :is_customer="is_customer"
        :cartQty="cartQty"
        @updateLogout="updateLogout"
      />

      <!-- <the-header></the-header> -->
      <div class="main_content">
        <router-view @updateLogin="updateLogin" @updateCartQty="updateCartQty" :key="$route.fullPath" />
      </div>
      
      <Footer />
      
    </div>
  </div>
  
</template>

<script>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import Navbar from "./components/nav/Navbar.vue";
import Footer from "./components/footer/Footer.vue";

//import  useRefresh from './composables/useRefresh.js'

export default {
  components: { Navbar, Footer },
  setup() {
    const router = useRouter();
    const store = useStore();

    const username = ref(null);
    const is_customer = ref(null)
    const cartQty = ref(0);

    const user = JSON.parse(localStorage.getItem("user"));

    function setUsername(user) {
      if (!!user && store.getters.isAuthenticated) {
        try {
          if (user["groups"].indexOf("Customers") >= 0) {
            if (user["company"] != "") username.value = user["company"]
            else username.value = user["custno"];
          } 
          else {
            username.value = user["name"];
          }
          is_customer.value = store.getters.isCustomer
        } catch (error) {
          //console.log(error)
        }
      }
    }

    

    //call this function after user login to update user information on the menu bar
    const updateLogin = () => {
      var user = store.getters.user;
      setUsername(user);
    };

    //call this funcion upon user logout
    const updateLogout = () => {
      username.value = null;
      is_customer.value = null;
      cartQty.value = 0;
      router.push({ name: "Home" });
    };

    const updateCartQty = async (qty=-1) => {
      if (!!user && store.getters.isAuthenticated && store.getters.isCustomer) {
        let cart_qty = await store.dispatch("getCartTotalQty");
        cartQty.value = cart_qty
      }
      else {
        cartQty.value = 0
      }

    };

    setUsername(user)
    updateCartQty()

    return { username, is_customer, cartQty, updateLogin, updateLogout, updateCartQty };
  },
};
</script>

<style scoped>
.main_content {
  min-height:800px;
}
  @media (min-width: 992px) and (max-width: 1650px) {
    .main_content {
      margin-top: 40px !important;
    }
  }
</style>
