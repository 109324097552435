module.exports = {
    BackendDomain: process.env.NODE_ENV === 'production'
    //? 'http://sandbox.malabs.com:8000'  	// For sandbox deployment
    ? 'https://api.malabs.com'  			// For production deployment
    :'http://local.malabs.com:8000'  		// For local frontend to connect local backend
	//: 'http://sandbox.malabs.com:8000'  	// For local frontend to connect sandbox backend
	,
    SiteKey: '6Lfe86gfAAAAAB7GzBb5u8x8z1UjQcgbDZfmW4H6', 
    StaticPath: "/media",
    MeasurementId: 'G-5RCJVCB747', // For Google Analytics on malabs.com

/*
    BackendDomain: process.env.NODE_ENV === 'production'
    ? 'http://sandbox.malabs.com:8000'
    //? 'https://api.malabs.com'
    : 'http://127.0.0.1:8000'
    ,

    SiteKey: process.env.NODE_ENV === 'production'
    ? '6LeYNRIeAAAAAMk7ixD-8hUklFMVkboocaXxoW9B'  //sandbox
    //? '6Lfe86gfAAAAAB7GzBb5u8x8z1UjQcgbDZfmW4H6'  //www.malabs.com
    : '6Ldw8UIdAAAAAPUfnX6Z3_PL5wIeZPZcsbXuIIYe'  //local
    ,

    StaticPath: "/media",

    //google analytics 
    MeasurementId: process.env.NODE_ENV === 'production'
    ? 'G-5RCJVCB747'  //www.malabs.com
    : 'G-VNGD0JYBKW'  //demo.malabs.com
    ,
*/
}