import { getAuthAPI, getAuthAPIPoSubmit} from "../../../utils/axios-api";

export default {
  checkOut(context, payload) {
    return new Promise((resolve, reject) => { 
      getAuthAPI.post('/order/check-out/', payload)
      .then(response => {
        var data = response.data
        context.commit('setDefaultAddress', data.default_address)
        context.commit('setCountryList', data.country_list)
        context.commit('setStateList', data.state_list)
        context.commit('setShippingMethods', data.ship_methods)
        let message = "Check out successfully."
        resolve(message)
      })
      .catch(err => {
        let message = ""
        try {
          for (var i = 0; i < err.response.data.message.length; i++) {
            message = message + err.response.data.message[i].error + " "
          }
        }
        catch(error) {
          message = "Failed to check out."
        }
        reject(message)
      })
    })
    
  },
  addToCart(context, payload) {
    return new Promise((resolve, reject) => { 
      getAuthAPI.post('/order/add-to-cart/', payload)
      .then(response => {
        let message = response.data.message
        let data = response.data.data
        
        resolve({"message": message, "data": data})
      })
      .catch(err => {
        let message = ""
        try {
          message = err.response.data.message
        }
        catch(error) {
          message = "Something is wrong"
        }
        reject(message)
      })
    })
    
  },
  getCart(context) {
    return new Promise((resolve, reject) => { 
      getAuthAPI.get('/order/get-cart/')
      .then(response => {
        var items = response.data
        resolve(items)
      })
      .catch(err => {
        let message = ""
        try {
          message = err.response.data.message[0]
        }
        catch(error) {
          message = "Something is wrong"
        }
        reject(message)
      })
    })
  },
  getCartTotalQty(context) {
    return new Promise((resolve, reject) => { 
      getAuthAPI.get('/order/get-cart/')
      .then(response => {
        var items = response.data
        var count = items.length
        var total_qty = 0
        for (var i=0; i< count; i++) {
            total_qty = total_qty + items[i]["quantity"]
        }
        //save to memory and local storage
        context.commit("setCartQty", total_qty)
        
        resolve(total_qty)
      })
      .catch(err => {
        //save to memory and local storage
        context.commit("setCartQty", 0)

        resolve(0)
      })
    })
  },
  updateCartItem(context, payload){
    return new Promise((resolve, reject) => { 
      getAuthAPI.post('/order/update-cart/', payload)
      .then(response => {
        let message = "The item has been updated."
        resolve(message)
      })
      .catch(err => {
        let message = ""
        try {
          message = err.response.data.message
        }
        catch(error) {
          message = "Something is wrong"
        }
        reject(message)
      })
    })
  },
  removeCartItem(context, payload){
    return new Promise((resolve, reject) => { 
      getAuthAPI.post('/order/update-cart/', payload)
      .then(response => {
        let message = "The item has been removed."
        resolve(message)
      })
      .catch(err => {
        let message = ""
        try {
          message = err.response.data.message
        }
        catch(error) {
          message = "Something is wrong"
        }
        reject(message)
      })
    })
  },
  submitPo(context,payload) {
    return new Promise((resolve, reject) => {
      getAuthAPIPoSubmit.post('/order/po-submit/',payload)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
    })
  }

};
