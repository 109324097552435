import { getAPI, getAuthAPI } from "../../../utils/axios-api";
import { detectBrowser, getCookie } from "../../../utils/common";

export default {
  login(context, payload) {
    return new Promise((resolve, reject) => { 
      getAPI.post('/account/token/', payload,
      {withCredentials: true, credentials: 'include'})
      .then(response => {
        
        var tokenData = {
          accessToken: response.data.data.access
        }
        var userData = {
          id: response.data.data.id,
          username: response.data.data.username,
          name: response.data.data.name,
          custno: response.data.data.custno,
          company: response.data.data.company,
          is_manager: response.data.data.is_manager,
          pay_type: response.data.data.pay_type,
          suspended: response.data.data.suspended,
          sales: response.data.data.sales,
          groups: response.data.data.groups,
          eblast: response.data.data.eblast,
        }
        
        //set data to memory and local storage
        context.commit('setToken', tokenData)
        context.commit('setUser', userData)
        
        let result = {status:'success', message:''}
        resolve(result)
      })
      .catch(err => {
        //handle error
        var status = 'failed'
        var message = ''
        
        if(typeof err.response === 'undefined') {
          message = "Something is wrong with the server. Failed to login."
        }
        else {
          if (err.response.status == 500) {
            message = "Something is wrong with the server. Failed to login."
          }
          else  {
            if (err.response.data.detail == undefined) {
              message = "Something is wrong. Failed to login."
            }
            else {
              message = err.response.data.detail
              if (message == "Your email address has not been verified.") {
                status = 'need to verify'
              }
            }
            
          }
        }
        
        //clear memory and remove local storage
        context.commit('setToken', {accessToken: ''})
        context.commit('setUser', {})
        
        let result = {status:status, message: message}
        resolve(result)
      })
    })
    
  },
  logout(context) {
    return new Promise((resolve, reject) => { 
      getAuthAPI.post('/account/token/logout/')
      .then(response => {

        //clear memory and remove local storage
        context.commit('setToken', {accessToken: ''})
        context.commit('setUser', {})
        
        let result = {status:'success', message:''}
        resolve(result)
      })
      .catch(err => {

        //clear memory and remove local storage
        context.commit('setToken', {accessToken: ''})
        context.commit('setUser', {})
        context.commit('setAuthStatus', '')
        
        let result = {status:'failed', message:''}
        resolve(result)
      })
      
    })
  },
  changepassword(context, payload) {
    return new Promise((resolve, reject) => { 
      getAuthAPI.post('/account/password/change/', payload)
      .then(response => {
        let result = {
          status: 'success',
          message: response.data.message
        }
        resolve(result)
      })
      .catch(err => {
        //handle error
        var message = ''
        if (err.response.status == 500) {
          message = "Something is wrong with the server. Failed to change the password."
        }
        else  {
          if (err.response.data.message == undefined) {
            message = "Something is wrong. Failed to change the password."
          }
          else {
            message = err.response.data.message
          }
          
        }
        let result = {
          status:'failed', 
          message: message
        }
        resolve(result)
      })
    })
  },
  verifyemail(context, payload){
    return new Promise((resolve, reject) => { 
      getAPI.post('/account/email-verify-request/', payload)
      .then(response => {
        context.commit('setAuthStatus', {status:'success', message: response.data.message})
        
        resolve(response)
      })
      .catch(err => {
        //handle error
        var message = ''

        if (err.response.status == 500) {
          message = "Something is wrong with the server. Failed to verify the email address."
        }
        else  {
          if (err.response.data.message == undefined) {
            message = "Something is wrong. Failed to verify the email address."
          }
          else {
            message = err.response.data.message
          }
          
        }
        context.commit('setAuthStatus', {status:'failed', message:message})
        
        reject(err)
      })
    })
  },
  logAccess(context, payload){
    return new Promise((resolve, reject) => { 
      payload["user_id"] =  context.getters.userId
      payload["custno"] = context.getters.custno
      payload["email"] = context.getters.userEmail
      payload["sales"] = context.getters.sales
      payload["browser"] = detectBrowser()["name"]
      payload["http_referer"] = document.referrer
      payload["accept_cookie"] = getCookie("cookienotice")
      payload["accept_cookie_timestamp"] = getCookie("cookienoticetime")
      
      getAPI.post('/account/log-access/', payload)
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        //handle error
        var message = ''

        if (err.response.status == 500) {
          message = "Something is wrong with the server. Failed for server's action."
        }
        else  {
          if (err.response.data.message == undefined) {
            message = "Something is wrong. Failed for server's action."
          }
          else {
            message = err.response.data.message
          }
          
        }
        
        reject(message)
      })
    })
  },
  updateSubscribeStatus(context, data) {
    context.commit('setEblast', data)
  }
};
