import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueGtag from "vue-gtag"
import { MeasurementId } from "./utils/settings.js";

import './assets/css/main.css'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css'

const app = createApp(App)

app.use(router)
app.use(store)
app.use(VueGtag, {
    config: { id: MeasurementId }
  }, router)
app.mount('#app')

