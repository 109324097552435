import { ref } from 'vue'

import store from '../store/index'
import { getAPI } from "./axios-api"

const status = ref(null)

const refresh = async () => {
    status.value = null
    
    if (!store.getters.isAuthenticated) {
        //clear memory and local storage
        store.commit('setToken', {accessToken: ''})
        store.commit('setUser', {})
        store.commit('setAuthStatus', '')
        
        status.value = "failed"
        return status.value
    }
    //need to set withCredentials to true so that httpOnly cookie will be sent with the request
    getAPI.defaults.withCredentials = true
    const response = await getAPI
    .post("/account/token/refresh/")
    .then(response => {
        var accessToken = response.data.data.access
        var refreshToken = response.data.data.refresh
        var data = {
            accessToken: accessToken,
            refreshToken: refreshToken
        }
        store.commit("setToken", data)
        status.value = "success"
    })
    .catch(err => {
        //clear memory and local storage
        store.commit('setToken', {accessToken: ''})
        store.commit('setUser', {})
        store.commit('setAuthStatus', '')

        status.value = "failed"
    })
    
    return status.value
}

const useRefresh = () => {
    return { status, refresh }
}

export default useRefresh